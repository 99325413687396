import ProfileIcon from '../ProfileIcon'
// import { logout } from '../../api'
import { useState } from 'react'
import PopupMenu from './PopupMenu'
import ClickAwayListener from 'react-click-away-listener'
import { useData } from '../../context/DataContext'

const PopupMenuButton = () => {
    const [open, setOpen] = useState(false)

    const clickHandler = () => { setOpen(!open) }
    const handleClickAway = () => { setOpen(false) }
    
    return(
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="popup-menu-container">
                <ProfileIcon name={"JM"} clickHandler={clickHandler}/>
                
                {open && <PopupMenu/>}
            </div>
        </ClickAwayListener>
    )
}
export default PopupMenuButton