import { createContext, useState, ReactNode, useMemo } from "react";
import { Snackbar, Alert } from "@mui/material";
import { accessToken, refreshToken } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import * as api from "../api/index";
// import jwtDecode from "jwt-decode";

const AuthContext = createContext({} as any);

export default AuthContext;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    // const [user, setUser] = useState<any>(null);
    // const [authenticated, setAuthenticated] = useState<boolean>(false);

    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const login = async (email: string, password: string) => {
        try {
            setLoading(true);
            const token = await api.login({
                username: email,
                password: password,
            });
            // const token = res;

            // const user = jwtDecode(token.access);
            // setUser(user);

            refreshToken.set(token.refresh);
            accessToken.set(token.access);
            // setAuthenticated(true);
            navigate("/");
        } catch (error: any) {
            displayError(error);
        } finally {
            setLoading(false);
        }
    };

    // const signUp = async (
    //     email: string,
    //     password: string,
    //     confirmPassword: string
    // ) => {
    //     console.log(email, password, confirmPassword);
    // };

    const logout = async () => {
        try {
            // await api.logout();

            refreshToken.remove();
            accessToken.remove();

            navigate("/login");
        } catch (error) {
            console.log(error);
        }
    };

    // const forgotPassword = async (email: string) => {
    //     try {
    //         await api.forgotPassword(email);
    //         navigate("/reset");
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const resetPassword = async (
    //     email: string,
    //     oldPassword: string,
    //     newPassword: string,
    //     confirmedPassword: string
    // ) => {
    //     try {
    //         const token = await api.resetPassword(
    //             email,
    //             oldPassword,
    //             newPassword,
    //             confirmedPassword
    //         );
    //         refreshToken.set(token.refresh);
    //         accessToken.set(token.access);

    //         navigate("/login");
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const displayError = (error: any) => {
        if (error.response) {
            if (error.response.data.detail) {
                setError(error.response.data.detail);
            } else {
                setError(
                    `Something went wrong. Error code: ${error.response.status}`
                );
            }
        } else if (error.message) {
            setError(error.message);
        } else {
            setError("Something went wrong, please try again later.");
        }
        setOpen(true);
    };

    // let contextData = {
    //     login: login,
    //     signUp: signUp,
    //     logout: logout,
    //     forgotPassword: forgotPassword,
    //     resetPassword: resetPassword,
    //     user: user,
    //     authenticated: authenticated,
    // };

    const contextData = useMemo(
        () => ({
            loading,
            error,
            login,
            logout,
            // signUp,
            // forgotPassword,
            // resetPassword,
        }),
        [loading, error, login, logout]
    );

    return (
        <AuthContext.Provider value={contextData}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                sx={{ top: { xs: 64 }, width: "100%" }}
            >
                <Alert onClose={handleClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            {children}
        </AuthContext.Provider>
    );
}
