import { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Typography,
} from "@mui/material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import {
    currencyFormatter,
    formatDateSlashed,
    percentageFormatter,
} from "../utils/formatters";
import { PurchaseDetail } from "../utils/types";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof PurchaseDetail;
    label: string;
    align: "left" | "right";
}

const headCells: HeadCell[] = [
    {
        id: "purchase_date",
        align: "left",
        label: "Date",
    },
    {
        id: "total_amount_collected",
        align: "right",
        label: "Collected Amount",
    },
    {
        id: "amount_purchased",
        align: "right",
        label: "Purchase Amount",
    },
    {
        id: "investor_ownership",
        align: "right",
        label: "Ownership %",
    },
    {
        id: "days_left_in_deal",
        align: "right",
        label: "Days Left in Deal",
    },
    {
        id: "active",
        align: "right",
        label: "Status",
    },

    {
        id: "deal",
        align: "right",
        label: "Deal ID",
    },
];

interface TableProps {
    data: PurchaseDetail[];
    onClick: (id: string) => any;
}

export default function EnhancedTable({ data, onClick }: TableProps) {
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] =
        useState<keyof PurchaseDetail>("purchase_date");
    const [selected, setSelected] = useState<string>("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof PurchaseDetail
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        setSelected(id);
        onClick(id);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => selected === id;

    const createSortHandler =
        (property: keyof PurchaseDetail) =>
        (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    // useEffect(() => {
    //     const getPurchaseDetail = async() => {
    //         const test = api.getPurchases()
    //         console.log(test)
    //     }
    //     getPurchaseDetail()
    // }, [])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box
            key={"deal-purchase-table"}
            sx={{ maxWidth: "1024px", width: "100%" }}
        >
            {data && data?.length > 0 ? (
                <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
                    <TableContainer>
                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            sortDirection={
                                                orderBy === headCell.id
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={createSortHandler(
                                                    headCell.id
                                                )}
                                                // sx={{ fontSize: 14, fontWeight: 500, color: palette.primary.main}}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(data, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    handleClick(event, row.id)
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                sx={{
                                                    "&:hover": {
                                                        cursor: "pointer",
                                                    },
                                                }}
                                            >
                                                <TableCell align="left">
                                                    {formatDateSlashed(
                                                        row.purchase_date
                                                    ).toString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatter(
                                                        row.total_amount_collected
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatter(
                                                        row.amount_purchased
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {percentageFormatter(
                                                        row.investor_ownership
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.days_left_in_deal}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.active
                                                        ? "Active"
                                                        : "Completed"}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.deal.id.slice(0, 8)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <VisibilityIcon
                                                        fontSize="small"
                                                        color="info"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Box sx={{ width: "100%", mt: 2 }}>
                    <Typography variant="subtitle1" color="text.secondary">
                        You have no deal purchases yet.
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
