// import ProfileIcon from '../ProfileIcon'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext';
import { useData } from '../../context/DataContext'

const PopupMenu = () => {
    let { logout } = useContext(AuthContext)
    let { investorSummary } = useData()
    
    return(
        <div className='popup-menu'>
            <div className="popup-menu-header">
                {/* <ProfileIcon name={"JM"}/> */}
                <div className="profile-info">
                    <h5>{investorSummary.company_name}</h5>
                </div>
            </div>

            <div className="popup-menu-footer" onClick={logout}>
                Sign out
            </div>
        </div>
    )
}
export default PopupMenu